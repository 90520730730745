export const reviews = [
  {
    id: 1,
    author: 'Joanna Wachowiak-Jurasz',
    position: 'CEO - GUESS POLSKA',
    content: 'Fleetinsider to narzędzie, które nie tylko ułatwia zarządzanie kosztami paliwa, ale także pozwala na szczegółowe porównanie cen oferowanych przez różne karty paliwowe. Co ważne, przy takim porównaniu zawsze widzimy różnicę wynoszącą co najmniej 1 eurocent na litrze, co może wydawać się niewielką kwotą, ale przy regularnym tankowaniu przekłada się na realne oszczędności. Dla jednego pojazdu oznacza to minimum 25 euro oszczędności miesięcznie, warto zaznaczyć że różnice czasem są aż do 10-20 eurocentów na litrze, więc kalkulator pomoże nam zobaczyć ile tak naprawdę da się zaoszczędzić. Szczególnie w sytuacji kiedy paliwo jest jednym z niewielu kosztów który można szybko ograniczyć bez ponoszenia większego ryzyka'
  },
  {
    id: 2,
    author: 'Iwona Henzel',
    position: 'CFO - DARTRANS & HENZEL',
    content: '…Fleetinsider to wszechstronne narz~dzie, kt6re nie tylko pomaga w zarzc4dzaniu kosztami paliwa, ale r6wniei znaczqco usprawnia kontrol~ wydatk6w na ptatne drogi. W naszej firmie transportowej, gdzie koszty zwiqzane z optatami drogowymi w r6inych krajach stanowiq istotny element budietu, moiliwosc monitorowania tych wydatk6w w jednym miejscu jest ogromnym udogodnieniem. Platforma umoiliwia szczeg6towq analiz~ optat drogowych z podziatem na kraje, pojazdy oraz r6ine sposoby ptatnosci, co pozwala nam szybko zidentyfikowac, w kt6rych regionach generujemy najwyisze koszty…'
  },
  {
    id: 3,
    author: 'Mikalai Krautsevich',
    position: 'CEO - EASY WAY',
    content: '…Fleetinsider stał się dla nas nieocenionym narzędziem. Dzięki tej platformie możemy szybko i łatwo porównać ceny paliwa z różnych kart, które posiadamy, wszystko w jednym miejscu. Wcześniej musieliśmy ręcznie sprawdzać i porównywać oferty, co było czasochłonne i często prowadziło do nieoptymalnych wyborów. Teraz mamy pełen przegląd cen, co pozwala nam podejmować lepsze decyzje i efektywniej zarządzać kosztami paliwa…'
  }
];
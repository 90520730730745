import dartrans from '../../assets/pictures/dartrans-logo_white.png';
import easyway from '../../assets/pictures/easyway_white.png';
import jasek from '../../assets/pictures/jasek-white.png';


export const logos = [
  {id: 1, logoSrc: dartrans, alt: 'dartrans'},
  {id: 2, logoSrc: easyway, alt: 'easyway'},
  {id: 3, logoSrc: jasek, alt: 'jasek'},
];
